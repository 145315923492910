/* stylelint-disable-next-line value-keyword-case */
/** Bulma: Variables **/
/** Animations **/
@-webkit-keyframes pulseWarning {
  0% {
    border-color: #ffdd57;
  }
  to {
    border-color: #947600;
  }
}
@keyframes pulseWarning {
  0% {
    border-color: #ffdd57;
  }
  to {
    border-color: #947600;
  }
}
@-webkit-keyframes pulseWarningBody {
  0% {
    background-color: #ffdd57;
  }
  to {
    background-color: #947600;
  }
}
@keyframes pulseWarningBody {
  0% {
    background-color: #ffdd57;
  }
  to {
    background-color: #947600;
  }
}
/** Main **/
.swal-modal {
  background-color: #363636;
}
.swal-modal.is-expanded {
  width: auto;
  max-width: 90%;
}
.swal-modal .field {
  text-align: initial;
}

.swal-title,
.swal-text {
  color: whitesmoke;
}

.swal-text {
  text-align: center;
}

.swal-content .label {
  color: whitesmoke;
}
.swal-content .checkbox,
.swal-content .radio {
  color: whitesmoke;
}
.swal-content .checkbox:hover,
.swal-content .radio:hover {
  color: #ededed;
}

.swal-button--confirm:not(.swal-button--danger) {
  background-color: #4387f4;
}
.swal-button--confirm:not(.swal-button--danger):hover {
  background-color: #0c53c6;
}
.swal-button--confirm:not(.swal-button--danger):focus {
  box-shadow: 0 0 0 1px #121212, 0 0 0 3px #0c53c6;
}

.swal-button--danger {
  background-color: #ff3860;
}
.swal-button--danger:hover {
  background-color: #db002c;
}
.swal-button--danger:focus {
  box-shadow: 0 0 0 1px #121212, 0 0 0 3px #db002c;
}

.swal-button--cancel {
  background-color: #00d1b2;
  color: white;
}
.swal-button--cancel:hover {
  background-color: #00947e;
}
.swal-button--cancel:focus {
  box-shadow: 0 0 0 1px #121212, 0 0 0 3px #00947e;
}

.swal-button--loading {
  color: transparent;
}

.swal-icon--info {
  border-color: #4387f4;
}
.swal-icon--info::after, .swal-icon--info::before {
  background-color: #4387f4;
}

.swal-icon--error {
  border-color: #ff3860;
}

.swal-icon--error__line {
  background-color: #ff3860;
}

.swal-icon--warning {
  border-color: #ffdd57;
  animation: pulseWarning 0.5s infinite alternate;
}

.swal-icon--warning__body,
.swal-icon--warning__dot {
  background-color: #ffdd57;
  animation: pulseWarningBody 0.5s infinite alternate;
}

.swal-icon--success {
  border-color: #55b978;
}
.swal-icon--success::after, .swal-icon--success::before {
  background-color: #363636;
}

.swal-icon--success__line {
  background-color: #55b978;
}

.swal-icon--success__hide-corners {
  background-color: #363636;
}

.swal-display-thumb-container {
  min-width: 200px;
  min-height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
}